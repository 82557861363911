
.Overview_img {
    width: 95%;
}

.Overview__unlockingParagraph {
    margin-top: 3rem !important;
}

@media (max-width:991px) {
    .Overview_img {
        width: 85%;
    }
} 

@media (max-width:767px) {
    .Overview .HexagonShape {
        margin-top: 1.5rem;
    }
}

@media (max-width:575px) {

    .Overview__unlockingParagraph {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
}
