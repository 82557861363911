.OverLay-loading {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 52%);
}

.Overlay-loading__text {
    font-size: 1.5rem;
    color: white;
    margin-left: 0.5rem;
}