.ImageTextItem {
    height: 130px;
}

.ImageTextItem__text {
    display: block;
    text-align: center;
    margin-top: 0.5rem;
}

.ImageTextItem__item {
    text-align: center;
}
