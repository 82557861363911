.Header {
    display: flex;
    background-color: #fff;
    padding: 0 1rem 0 1rem;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 2;
    position:  fixed !important;
    width: 100%;
    height: 10vh;
}

.Header nav{
    width: 100%
}

.Header__logo {
    width: 350px;
}

@media (max-width:991px) {
    .Header {
        height: 64px;
    }

    .Header.navbar{
        padding: 0;
    }

    .Header .navbar-collapse {
        box-shadow: -3px 20px 5px 1px #10101014, 0px 17px 16px 3px #0000002b;
        background: #fff;
    }

    .Header > button {
        margin-right: 2rem;
    }

    .Header > div {
        padding-left: 2rem;
    }

    .Header > a{
        margin-bottom: 0.5rem;
        padding-left: 2rem;
    }
}

@media (max-width:575px) {
    .Header {
        height: auto;
    }

    .Header__logo {
        width: 150px;
    }

    .Header > button {
        margin-right: 1rem;
    }

    .Header > div {
        margin-top: 0.5rem;
    }

    .Header > a{
        margin-bottom: 0.5rem;
        padding-left: 1rem;
    }
}
