.ButtonOutline {
    color: var(--brand5) !important;
    background-color: #fff !important;
    border: 2px solid var(--brand5)!important;
    border-radius: 2px !important;
    font-weight: 400 !important;
    outline: transparent !important;
    font-size: 1.1rem !important;
    letter-spacing: 0.2rem;
}

.ButtonOutline:hover {
    border-color: var(--brand6) !important;
    color: var(--brand6) !important;
    box-shadow: none !important;
    background-color:  #6a3c961f !important;
}

.ButtonOutline:focus {
    box-shadow: none !important;
}