.ContactUsForm {
    background-color: #e4e3e359;
    padding: 0.7rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 7px 5px #00000029;
    width: 100%;
}

.ContactUsForm__row {
    margin-top: 0.8rem;
}

.ContactUsForm__row label {
    margin-bottom: 0.3rem;
}

.ContactUsForm__form {
    background-color: #fff;
    padding: 2rem;
}

.ContactUsForm__form input, .ContactUsForm__form textarea{
    border: 1px solid var(--neutral2);
    background-color: #eaeaea;
    border-radius: 0.1rem !important;
    box-shadow: inset 0 0 9px 2px #bbbbbb5e;
}

.ContactUsForm__form input {
    height: 40px;
}

.ContactUsForm__form input:focus, .ContactUsForm__form textarea:focus {
    box-shadow: inset 0 0 7px 5px #bbbbbb5e !important;
    background-color: #eaeaea;
    border-color: var(--neutral2);
}

.ContactUsForm textarea{
    height: 50px;
}

@media (max-width: 991px) {
    .ContactUsForm {
        padding:1rem;
        width: 100%;
    }

    .ContactUsForm__form {
        padding: 1.5rem;
    }

    .ContactUsForm__form input {
        height: auto;
    }

    .ContactUsForm textarea{
        height: 40px;
    }

    .ContactUsForm .paragrahp4{
        font-size: 0.7rem;
    }
}

@media (max-width:767px) {

}

@media (max-width: 575px) {
    .ContactUsForm {
        padding: 0;
        margin-top: 1.5rem;
        box-shadow: none;
    }

    .ContactUsForm__form {
        margin: 0;
        padding: 0;
    }

    .ContactUsForm button{
        margin-top: 1rem;
    }
}

@media (min-width: 1200px) {
    .ContactUsForm__button {
        margin-left: 1rem;
    }
}