a.Menu_item {
    padding: 0.5rem 1rem;
    color: var(--text2);
    font-weight: 600;
    position: relative;
}

a.Menu_item:hover, a.Menu_item:focus {
    color: var(--highlight);
}

a.Menu_item.Menu_item--active {
    color: var(--highlight);
    font-weight: 800;
}

@media  (min-width: 992px) {
    a.Menu_item.Menu_item--active span::after {
        content: '';
        width: 40%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--highlight); ;
    }
}
