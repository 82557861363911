    .HexagonShape {
       height: 300px !important;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
    }

    .HexagonShape__title {
        margin-top: 1rem; 
    }

    .HexagonShape__text {
        margin-top: 0.8rem; 
        width: 200px;
        text-align: center;
        font-size: 0.9rem;
    }

    .HexagonShape__hexa1 {
        background: url(../../assets/images/overview/hexaAz1.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .HexagonShape__hexa2 {
        background: url(../../assets/images/overview/hexaAz2.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .HexagonShape__hexa3 {
        background: url(../../assets/images/overview/hexaAz3.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .HexagonShape__hexa4 {
        background: url(../../assets/images/overview/hexaAz4.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }


@media (max-width: 400px) {
    .HexagonShape {
        height: 250px !important;
    }

    .HexagonShape__title {
        margin-top: 0; 
        width: 150px;
        text-align: center;
    }

    .HexagonShape__text {
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
        width: 150px;
    }
}