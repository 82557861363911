.Item{
    display: flex;
    align-items: center;
    margin-bottom: 1.3rem;
}

.Item__img {
    width: 25px;
}

