.Message--success .modal-header > div{
    color: #4caf50;
}

.Message--success .modal-content {
    border: 3px solid #4caf50;
}

.Message--error .modal-header > div{
    color: #f44336;
}

.Message--error .modal-content {
    border: 3px solid #f44336;
}


.Message--warning .modal-header > div{
    color: #fbc02d;
}

.Message--warning .modal-content {
    border: 3px solid #fbc02d;
}

.modal-header, .modal-footer {
    border-bottom: 0px !important;
    border-top: 0px !important;
}