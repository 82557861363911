    :root {
        --text1: #111;
        --text2: #6d6e71;
        --highlight: #6a3c96;
        --neutral1: #f5f4f6;
        --neutral2: #d7d9da;
        --neutral3: #d11e4d;
        --brand1: #30b6e9;
        --brand2: #26a1dc;
        --brand3: #1a6db3;
        --brand4: #465dab;
        --brand5: #6a3c96;
        --brand6: #603181;

    }

    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 100;
        src: url('../../assets/fonts/exo-v8-latin-100.eot'); /* IE9 Compat Modes */
        src: local('Exo Thin'), local('Exo-Thin'),
            url('../../assets/fonts/exo-v8-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-100.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-100.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-200 - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 200;
        src: url('../../assets/fonts/exo-v8-latin-200.eot'); /* IE9 Compat Modes */
        src: local('Exo ExtraLight'), local('Exo-ExtraLight'),
            url('../../assets/fonts/exo-v8-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-200.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-200.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-300 - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 300;
        src: url('../../assets/fonts/exo-v8-latin-300.eot'); /* IE9 Compat Modes */
        src: local('Exo Light'), local('Exo-Light'),
            url('../../assets/fonts/exo-v8-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-300.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-300.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-regular - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 400;
        src: url('../../assets/fonts/exo-v8-latin-regular.eot'); /* IE9 Compat Modes */
        src: local('Exo Regular'), local('Exo-Regular'),
            url('../../assets/fonts/exo-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-regular.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-500 - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 500;
        src: url('../../assets/fonts/exo-v8-latin-500.eot'); /* IE9 Compat Modes */
        src: local('Exo Medium'), local('Exo-Medium'),
            url('../../assets/fonts/exo-v8-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-500.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-500.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-600 - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 600;
        src: url('../../assets/fonts/exo-v8-latin-600.eot'); /* IE9 Compat Modes */
        src: local('Exo SemiBold'), local('Exo-SemiBold'),
            url('../../assets/fonts/exo-v8-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-600.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-600.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-700 - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 700;
        src: url('../../assets/fonts/exo-v8-latin-700.eot'); /* IE9 Compat Modes */
        src: local('Exo Bold'), local('Exo-Bold'),
            url('../../assets/fonts/exo-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-700.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-700.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-800 - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 800;
        src: url('../../assets/fonts/exo-v8-latin-800.eot'); /* IE9 Compat Modes */
        src: local('Exo ExtraBold'), local('Exo-ExtraBold'),
            url('../../assets/fonts/exo-v8-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-800.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-800.svg#Exo') format('svg'); /* Legacy iOS */
    }
    /* exo-900 - latin */
    @font-face {
        font-family: 'Exo';
        font-style: normal;
        font-weight: 900;
        src: url('../../assets/fonts/exo-v8-latin-900.eot'); /* IE9 Compat Modes */
        src: local('Exo Black'), local('Exo-Black'),
            url('../../assets/fonts/exo-v8-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../../assets/fonts/exo-v8-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-900.woff') format('woff'), /* Modern Browsers */
            url('../../assets/fonts/exo-v8-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../../assets/fonts/exo-v8-latin-900.svg#Exo') format('svg'); /* Legacy iOS */
    }

    html {
        scroll-snap-type: y mandatory;
    }

    body {
        background-color: #fff;
        color: var(--text1);
        font-family: 'Exo', serif;
        font-weight: 400;
        height: 100%;
    }

    h1 {
        font-weight: 600;
        font-size: 3rem;
    }

    h2 {
        font-weight: 400;
    }

    h3 {
        font-weight: 700;
        font-size: 1rem;
    }

    .bold{
        font-weight: 700;
    }

    .paragraph1 {
        font-size: 1.2rem;
        margin: 0;
    }

    .paragraph2 {
        font-size: 1rem;
        margin: 0;
    }

    .paragraph3 {
        font-size: 0.95rem;
        margin: 0;
    }

    .paragraph4 {
        color: var(--text2);
        font-size: 0.95rem;
        margin: 0;
    }

    .wrapper {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .App__wrapperPages{
        padding: 10vh 0 0 0;
    }

    .App__pages{
    }

    .App__section {
        min-height: 100vh;
        padding-top: 10vh;
        scroll-snap-align: start;
    }

    @media (max-width: 991px) {
        .wrapper {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        .App__section {
            min-height: auto;
            padding-top: 2rem;
            padding-bottom: 1.5rem;
        }

        .App__wrapperPages{
            padding: 64px 0 0 0;
        }
    }

    @media (max-width: 575px) {
        .wrapper {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .App__pages{
            margin-top: 1.5rem;
        }

        .App__section {
            padding-bottom: 0;
        }

        .App__wrapperPages{
        }
    }
