
.Solutions__img {
    width: 100%;
}

.Solutions_platformParagraph, 
.Solutions__valuableParagraph, 
.Solutions__coveredParagraph, 
.Solutions__businessParagrahp,
.Solutions__protectingParagrahp,
.Solutions__technologyParagraph,
.Solutions__keepParagraph {
    margin-top: 3rem !important;
}

.Solutions__technologyParagraph2, .Solutions__keepParagraph2, .Solutions__keepParagraph3 {
    margin-top: 2rem !important;
}

.Solutions_platformParagraph2 {
    width: 77%
}

.Solutions__valuableParagraph {
    width: 58%;
}

.Solutions__coveredParagraph {
    width: 71%;
}

.Solutions__businessParagrahp {
    width: 45.5%;
}

.Solutions__protectingParagrahp {
    width: 55%;
}

.Solutions__technologyParagraph, .Solutions__technologyParagraph2 {
    width: 48%;
}

.Solutions__keepParagraph2 {
    width: 76%;
}

@media (min-width:1280px) {
    .Solutions_platformParagraph2,
    .Solutions__valuableParagraph,
    .Solutions__coveredParagraph,
    .Solutions__businessParagrahp,
    .Solutions__protectingParagrahp,
    .Solutions__technologyParagraph,
    .Solutions__technologyParagraph2,
    .Solutions__keepParagraph2 {
        width: 80%;
    }
}

@media (max-width:991px) {
    .Solutions__img {
        width: 90%;
    }

    .Solutions__img_soa {
        width: 80%;
    }

    .Solutions__img_infraestructure {
        width: 86%;
    }

    .Solutions__img_authentication {
        width: 86%;
    }

    .Solutions__img_trade {
        width: 95%;
    }

    .Solutions_platformParagraph2, 
    .Solutions__valuableParagraph, 
    .Solutions__coveredParagraph, 
    .Solutions__img_business,
    .Solutions__businessParagrahp,
    .Solutions__protectingParagrahp,
    .Solutions__technologyParagraph,
    .Solutions__technologyParagraph2,
    .Solutions__keepParagraph,
    .Solutions__keepParagraph2,
    .Solutions__keepParagraph3{
        width: 100%
    }
    
} 


@media (max-width:767px) {
    
}

@media (max-width:575px) {

    .Solutions .App__section {
        margin-top: 2rem !important;
    }

    .Solutions_platformParagraph,
    .Solutions__coveredParagraph,
    .Solutions__businessParagrahp,
    .Solutions__protectingParagrahp,
    .Solutions__technologyParagraph,
    .Solutions__keepParagraph {
        margin-top: 1.5rem !important;
    }

    .Solutions__technologyParagraph2, .Solutions__keepParagraph2, .Solutions__keepParagraph3 {
        margin-top: 1rem !important;
    }

    .Solutions__img_infraestructure {
        width: 70%
    }

    .Solutions__img_authentication, .Solutions__img_business {
        width: 80%
    }

    .Solutions__img {
        margin-top: 1.5rem;
    }

    .Solutions .ImageTextItem {
        height: auto;
        margin-top: 1.5rem;
    }

    .Solutions .ImageTextItem__item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Solutions .ImageTextItem__image {
        width: 60%;
    }

}
