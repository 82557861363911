.Home {
    scroll-snap-align: start;
    min-height: 100vh;
    padding-top: 10vh;
}

.Home_row {
    background: url('../../assets/images/home/hexagons2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vh;
}

.Home_why {
    height: 30vh;
}

.Home_title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.Home__titleParagraph {
    width: 58%
}

.Home_image {
    width: 70px;
    margin-bottom: 0.5em;
}


@media (max-width: 991px) {

    h1{
        font-size: 2rem !important;
    }

    .Home_row {
        height: 350px;
    }

    .Home_why {
        height: auto;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    .Home_why > div:first-child {
        margin-bottom: 2rem;
    }
}

@media (max-width: 575px) {
    .Home__titleParagraph {
        width: auto;
    }

    .Home_why > div:first-child {
        margin-bottom: 0rem;
    }

    .Home_row {
        background: url('../../assets/images/home/hexagons.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .Home__whyImageList  .ImageTextItem{
        margin-top: 1.5rem;
    }

}
