.ContactUs {
    box-sizing: border-box;
    padding-bottom: 0.5rem;
}

.ContactUs__contactParagrahp{
    margin-top: 3rem !important;
}

@media (max-width:767px) {
}

@media (max-width:575px) {
    .ContactUs__contactParagrahp{
        margin-top: 1.5rem !important;
    }
}