.About_info > div {
    height: 182px;
}

.About_info .paragraph2 {
    margin-top: 1.5rem;
}

.About_info__title {
    display: flex;
    height: 85px;
    align-items: flex-start;
    flex-direction:column; 
}

.About__cityImage {
    width: 100%;
}

.About_cityName {
    text-align: center;
    margin-top: 0.8rem;;
}

.About__list {
    display: flex;
    flex-direction: row;
    margin-top: 1.3rem; 
}

.About__list div{
    margin-right: 0.8rem; 
}

.About__industry_paragraph {
    width: 68%;
}



.About__location{
    margin-top: 3rem;
}


@media (max-width: 991px) {

    .About_info > div {
        height: 270px;
    }

    .About__list div{
        margin-right: 0.2rem; 
    }

    .About__listTitle {
        margin-top: 0;
    }

    .About__industry_paragraph {
        margin-top: 2rem !important;
        width: 100%;
    }

    .About__location {
        margin-top: 2rem;
    }

    .About__locationCity {
        margin-top: 1rem; 
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .About__vision {
        margin-top: 2rem;
    }

}

@media (max-width: 767px) {

    .About__locationCity {
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 575px) {
    .About_info__title{
        height: auto;
    }

    .About_info .paragraph2 {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .About__listTitle{
        margin-top: 2rem; 
    }

    .About__list{
        margin-top: 1.5rem;
    }

    .About_info > div {
        height: auto;
    }

    .About__list div{
        margin-right: 0rem; 
        align-items: flex-start;
    }

    .About__industry_paragraph{
        margin-top: 1.5rem !important;
        width: auto;
    }

    

    

}